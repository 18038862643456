<template>
    <o-row-container>
        <div class="row-container">
            <VersionsToCompare :versionsCompare="versionsCompare" @sourceChange = "sourceChanged" :type="type" :currentVersion="currentVersion"></VersionsToCompare>
        </div>
        <o-sizer-panel height="70%" id="id_versionCompare">
            <Overlay v-if="isLoading"/>
            <Compare :source1 = "source1" :source2 = "source2" class="w-100 pt-2"></Compare>
        </o-sizer-panel>
    </o-row-container>
</template>
<script setup lang="ts">
    import {getVersionsCompare} from "o365.modules.VersionsCompare.ts";
    import VersionsToCompare from "o365.vue.components.VersionsToCompare.vue";
    import Compare from 'o365.vue.components.CodeCompare.vue';
    import { Overlay } from 'o365.vue.components.jsx';
    import {ref} from "vue";

   
    const source1 = ref({fileName:"Loading",code:"Loading..."});
    const source2 = ref({fileName:"Loading",code:"Loading..."});
    const isLoading = ref(true);
    
    const props = defineProps({
        fileName:{
            type:String,
            required:true
        },
        type:{
            type:String,
        },
        currentVersion: {
            type: Number,
            default: 0
        }   
    })

    const versionsCompare =  getVersionsCompare(props.fileName,props.type, props.currentVersion);

    const sourceChanged = () =>{

        isLoading.value = true;

        if (props.type === 'DBObject') {
            const data = versionsCompare.getCurrentDBSourceData();
            source1.value = data.source1;
            source2.value =  data.source2;
            isLoading.value = false;
            
        } else {
            versionsCompare.getCurrentSourceData().then(data => {
                source1.value = data.source1;
                source2.value = data.source2;
                isLoading.value = false;
            })
        }
    }

</script>